import {
  RefObject,
  useEffect, useState, useCallback, ReactNode,
} from 'react';

import { debounce } from '@Utils';

const getPrevElement = (list: Element[]) => {
  const sibling = list[0].previousElementSibling;

  if (sibling instanceof HTMLElement) {
    return sibling;
  }

  return null;
};

const getNextElement = (list: Element[]) => {
  const sibling = list[0].nextElementSibling;

  if (sibling instanceof HTMLElement) {
    return sibling;
  }

  return null;
};

const isNotDummyChild = (child: Element) => child.getAttribute('data-id') !== 'dummy-child';

const isChildVisibleInContainer = (
  containerRect: DOMRect,
  childRect: DOMRect,
) => Math.ceil(childRect.left) >= Math.ceil(containerRect.left)
        && Math.ceil(childRect.right) <= Math.ceil(containerRect.right);

export function isLastChildInView(containerElement: HTMLDivElement, lastChild: Element) {
  const containerRect = containerElement.getBoundingClientRect();
  const lastChildRect = lastChild.getBoundingClientRect();

  return isChildVisibleInContainer(containerRect, lastChildRect);
}

const getContainerChildren = (element: HTMLElement) => Array
  .from(element.children)
  .filter(isNotDummyChild) as HTMLElement[];

const isDisabledNextElement = (
  ref: RefObject<HTMLDivElement>,
  nextElement: HTMLElement,
): boolean => {
  const containerElement = ref?.current as HTMLDivElement;

  if (!containerElement) {
    return false;
  }

  const containerChildren = getContainerChildren(containerElement);

  return containerChildren[containerChildren.length - 1] === nextElement;
};

interface UsePosition {
  ref: RefObject<HTMLDivElement>;
  children: ReactNode;
  lastItemScrollOffset: number;
  disabledLoopScroll?: boolean;
}
export function usePosition({
  ref,
  children,
  lastItemScrollOffset = 0,
  disabledLoopScroll = false,
}: UsePosition) {
  const [ prevElement, setPrevElement ] = useState<HTMLElement | null>(null);
  const [ nextElement, setNextElement ] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const containerElement = ref.current as HTMLDivElement;

    const update = () => {
      const containerRect = containerElement.getBoundingClientRect();
      const visibleElements = getContainerChildren(containerElement).filter((child) => {
        const childRect = child.getBoundingClientRect();
        const isVisible = isChildVisibleInContainer(containerRect, childRect);

        return isVisible;
      });

      if (visibleElements.length > 0) {
        setPrevElement(getPrevElement(visibleElements));
        setNextElement(getNextElement(visibleElements));
      }
    };

    update();

    const debouncedUpdate = debounce(update, 100);

    containerElement.addEventListener('scroll', debouncedUpdate, { passive: true });

    return () => {
      // Typescript does accept passive as an option currently
      // @ts-ignore
      containerElement.removeEventListener('scroll', debouncedUpdate, { passive: true });
    };
  }, [ ref, children ]);

  const scrollToElement = useCallback((
    element: HTMLElement | null,
    direction: 'left' | 'right',
  ) => {
    const containerElement = ref.current;

    if (!containerElement) {
      return;
    }

    let newScrollPosition: number | undefined;
    const containerChildren = getContainerChildren(containerElement);
    const lastChild = containerChildren[containerChildren.length - 1];
    const offsetLastChild = containerChildren[containerChildren.length - 1 - lastItemScrollOffset];

    const lastChildInView = isLastChildInView(containerElement, lastChild);

    if (direction === 'left' && !element) {
      newScrollPosition = offsetLastChild.offsetLeft;
    } else if (direction === 'right' && lastChildInView) {
      newScrollPosition = (containerElement.children[0] as HTMLElement).offsetLeft;
    } else {
      newScrollPosition = element?.offsetLeft;
    }

    containerElement.scroll?.({
      left: newScrollPosition,
      behavior: 'smooth',
    });
  },
  [ ref ]);

  const scrollLeft = useCallback(() => scrollToElement(prevElement, 'left'), [
    scrollToElement,
    prevElement,
  ]);

  const scrollRight = useCallback(() => scrollToElement(nextElement, 'right'), [
    scrollToElement,
    nextElement,
  ]);

  return {
    scrollRight,
    scrollLeft,
    disabledScrollLeft: Boolean(disabledLoopScroll && !prevElement),
    disabledScrollRight: Boolean(disabledLoopScroll && isDisabledNextElement(ref, nextElement)),
  };
}

