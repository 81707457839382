import { SxStyleProp } from 'theme-ui';

export const disabledCarouselStyles = (
  columns = [ 0, 2, 0 ],
  rows = [ 0, 2, 0 ],
): SxStyleProp => ({
  // For legacy flexbox
  flexWrap: 'wrap',

  // For css grid
  '@supports (display: grid)': {
    gridTemplateColumns: columns.map((col) => (col ? `repeat(${col}, 1fr)` : 'none')),
    gridTemplateRows: rows.map((row) => (row ? `repeat(${row}, 1fr)` : 'none')),
    gridAutoFlow: columns.map((col) => (col ? 'row !important' : 'column !important')),
  },
});
