import React from 'react';

import { Button, ComponentProps } from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';

interface ScrollButtonsProps extends ComponentProps {
  scrollLeft: () => void;
  scrollRight: () => void;
  disabledScrollLeft: boolean;
  disabledScrollRight: boolean;
}

export const ScrollButtons: React.FC<React.PropsWithChildren<ScrollButtonsProps>> = ({
  className,
  scrollLeft,
  scrollRight,
  disabledScrollLeft,
  disabledScrollRight,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={className}
      sx={{
        display: [ 'none', null, 'block' ],
        whiteSpace: 'nowrap',
      }}
    >
      <Button
        data-id="carousel-button-previous"
        variant="Outline"
        size="48"
        icon="Actions/MovePrevious"
        onClick={scrollLeft}
        ariaLabel={t('previous')}
        disabled={disabledScrollLeft}
        sx={{
          marginRight: '2xs',
        }}
      />
      <Button
        data-id="carousel-gallery-button-next"
        variant="Outline"
        size="48"
        icon="Actions/MoveNext"
        onClick={scrollRight}
        disabled={disabledScrollRight}
        ariaLabel={t('next')}
      />
    </div>
  );
};
